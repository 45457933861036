.search-select
  &__input
    width: 100%
    height: 26px
    transform: translateY(1px)
    white-space: nowrap
    box-sizing: border-box
    overflow: hidden
    outline: none
    color: #1B214A
    font-size: 18px
    line-height: 26px
    transition: all 0.2s ease-in