.react-datepicker
  display: inline-block
  position: relative
  border: none
  padding: 20px 20px 14px 20px
  background-color: #fff
  border-radius: 6px
  box-shadow: 0 3px 15px rgb(0 0 0 / 7%)
  box-sizing: border-box

  &-wrapper
    display: inline-block
    border: 0
    padding: 0 0 0 7px

  &-popper
    z-index: 31

  &-time
    &__header
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden

  &__day-names
    display: flex
    justify-content: space-around
    font-size: 14px
    letter-spacing: 0.07px
    padding: 16px 0 5px 10px
    color: $brand-gray-xDark
    font-weight: 400
    white-space: nowrap
    line-height: 16px

  &__week
    white-space: nowrap
    display: flex
    justify-content: space-around
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.07px
    padding-bottom: 5px

  &__day
    background-color: transparent
    color: $brand-blue
    width: 33px
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.07px
    padding: 8px 0 8px 0
    text-align: center
    display: inline-block
    border-radius: 50%
    transition: all 300ms
    cursor: pointer

    &:hover
      background: $brand-orange
      color: #FFFFFF

    &--disabled
      cursor: default
      background-color: transparent
      color: $brand-gray-dark

      &:hover
        color: $brand-gray-dark
        background-color: transparent

    &--selected
      background-color: $brand-orange
      color: #FFFFFF

      &:hover
        background-color: $brand-dark-orange

  &__input-container input
    border: none
    background: transparent
    font-size: 15px
    line-height: 26px
    color: $brand-blue
    letter-spacing: 0.15px
    max-width: 125px
    cursor: pointer
    padding: 0
    text-align: left

  &__day--outside-month
    background-color: transparent !important
    color: transparent !important
    pointer-events: none

.custom-react-datepicker
  &__header
    display: flex
    justify-content: space-between

  &__select
    border: 1px solid $border-color
    border-radius: 4px
    font-weight: normal
    color: $brand-gray-dark
    font-size: 14px
    line-height: 26px
    cursor: pointer
    width: 100px
    height: 36px
    margin: 0 4px

.react-datepicker__portal
  position: fixed
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, 0.8)
  left: 0
  top: 0
  justify-content: center
  align-items: center
  display: flex
  z-index: 2147483647

.react-datepicker__close-icon
  cursor: pointer
  background-color: transparent
  border: 0
  outline: 0
  padding: 6px 6px 0 0
  position: absolute
  top: 0
  right: 0
  display: table-cell
  vertical-align: middle

.react-datepicker__close-icon::after
  cursor: pointer
  color: $brand-dark-blue
  border-radius: 50%
  height: 16px
  width: 16px
  padding: 2px
  font-size: 20px
  line-height: 1
  text-align: center
  display: table-cell
  vertical-align: middle
  content: "×"
