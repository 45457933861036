#main-footer
  background: #ffffff
  height: auto
  display: flex
  align-items: center    
  width: 100%
  padding-top: 20px
  padding-bottom: 20px
  box-shadow: 0px -4px 10px 0px #e5e7eb

  .footer-wrap
    display: flex
    flex: 1
    flex-wrap: wrap
    font-size: 16px
    max-width: 1170px    
    margin: 0 auto
    +media-query(phone)
      flex-direction: row
    .footer-full 
      padding: 10px
      text-align: center
      width: 100%
      
    .footer-column
      padding: 10px
      text-align: center
      flex: 33.3%
      +media-query(phone)
        flex: 100%
      .footer-column-center-wrapper 
        display: inline-block
        a
          justify-content: left
      
      img 
        flex: 1
        margin-right: 5px
      a
        display: flex
        width: 100%
        padding: 10px
        align-items: center
        justify-content: center
        color: #1b214a
        
        svg
          margin-right: 10px
          width: 20px        
    
    .footer-contacts
      div
        a
          padding: 0

  .payment-card-logo  
    max-height: 30px
    margin: 10px
  .payment-card-logo-mir
    max-height: 25px
  .payment-card-logo-modulbank
    max-height: 40px
    margin-top: -5px

  
  