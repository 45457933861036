.summary-container
  width: 770px
  margin: auto
  background: #FBFBFB
  +media-query(desktop)
    max-width: 770px
    width: 100%

.title
  margin: 0 auto 30px auto
  +media-query(tablet)
    font-size: 16px
  span
    color: $brand-gray-medium
    font-size: 18px

.summary-box-wrap
  margin: 0 auto
  border-radius: 6px
  background: $brand-gray-light
  opacity: 1
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.03)
  box-sizing: border-box
  padding: 29px 30px 23px 30px
  margin-bottom: 20px
  +media-query(tablet)
    padding: 19px 20px 13px 20px

.summary-table
  border-collapse: collapse
  width: 100%
  margin-top: 16px
  thead
    tr
      th

        background: #2B388F
        color: #fff
        font-size: 17px
        line-height: 25px
        letter-spacing: 0.34px
        font-weight: 600
        padding: 18px 29px 15px 29px
        border: 1px solid #5560A6
        text-align: left
        box-sizing: border-box
        border-top: none
        border-bottom: none
        +media-query(tablet)
          padding: 18px 4px 15px 4px
          text-align: center
  tbody
    tr
      td
        background: #ffffff
        color: #1B214A
        font-size: 15px
        line-height: 25px
        font-weight: 400
        letter-spacing: 0.3px
        padding: 12px 29px 12px 29px
        +media-query(tablet)
          padding: 10px 14px 10px 14px
          word-break: break-word
        &:hover
          background-color: #E6F7E5
      &:nth-child(even)
        td
          background: #FAFAFC
          &:hover
            background-color: #E6F7E5
  tr
    td
      border: 1px solid #E9EAEF
      text-align: left
      box-sizing: border-box


.table-item
  border: 1px solid #E9EAEF
  padding: 12px 29px 12px 29px
  &:hover
    background-color: #E6F7E5

.summary-list
  padding: 0 0 5px 3px
  margin: -27px 0 0 0
  li
    padding: 0
    margin: 25px 0 0 0
    display: flex
    font-size: 17px
    line-height: 25px
    color: $brand-gray-dark
    letter-spacing: 0.34px
    list-style: none
    +media-query(tablet)
      font-size: 15px

.checkout-summary-label
  width: 190px
  color: $brand-dark-blue
  +media-query(tablet)
    max-width: 140px
    min-width: 140px

.checkout-box-cancellation
  padding-top: 33px
  padding-bottom: 30px

.heckout-cancellation
  margin-top: 10px

.checkout-cancellation-wrap
  display: flex
  margin: 0 -25px
  > div
    width: calc(50% - 50px)
    margin: 0 25px
    display: flex
    flex-direction: row

.checkout-cancellation-divider
  flex-grow: 10
  position: relative
  &:before
    content: ''
    position: absolute
    left: 18px
    top: 10px
    display: block
    width: calc(100% - 33px)
    height: 3px
    background: url('../../images/bg-divider.svg') repeat-x

.checkout-cancellation-value,
.checkout-cancellation-time
  color: #1b214a
  font-size: 16px
  line-height: 20px
  letter-spacing: 0.32px

.checkout-cancellation-time span
  display: inline-block
  margin-right: 7px

.checkout-box-details
  padding: 29px 27px 16px 29px

  label
    display: block
    color: #1b214a
    font-size: 16px
    line-height: 26px
    letter-spacing: 0.32px
    margin: 0 0 14px 0
  .custom-input
    margin-bottom: 19px
    margin-top: 0px

.form
  &-row
    margin: 0 -11px
    display: flex
    flex-direction: row
    +media-query(tablet)
      flex-direction: column
  &-col
    width: calc(50% - 22px)
    margin: 0 11px
    +media-query(tablet)
      width: calc(100% - 22px)
    &-full
      margin: 0 11px
      width: calc(100% - 22px)

.checkout-box-payment
  padding: 0

.payment-select-item
  border-top: 1px solid $brand-gray-xlight
  background: #ffffff
  box-sizing: border-box
  display: flex
  cursor: pointer
  transition: background 300ms
  padding: 22px 27px 22px 27px
  +media-query(tablet)
    padding: 20px
  &.is-active
    background: $brand-gray-light

  &:first-child
    border: none

.payment-radio-flag
  min-width: 19px
  max-width: 19px
  height: 19px
  position: relative
  &:before
    content: ''
    display: block
    width: 19px
    height: 19px
    border: 2px solid $brand-gray-border
    border-radius: 50%
    box-sizing: border-box
  &:after
    content: ''
    display: block
    position: absolute
    left: 5px
    top: 5px
    width: 9px
    height: 9px
    border-radius: 50%
    background: $brand-orange
    opacity: 0
    transition: opacity 300ms
  &_is-active
    &:after
      opacity: 1

.payment-description
  color: $brand-gray-dark
  letter-spacing: 0.15px
  h5
    margin: 0 0 9px 0
    letter-spacing: 0.4px

.payment-select-item
  &--mastercard
    padding: 30px 27px 20px 27px
    +media-query(tablet)
      padding: 20px
      transform: translateY(4px)
  &--paypal

    transform: translateY(5px)
  &--cash
    transform: translateY(3px)

  svg
    min-width: 90px
    max-width: 90px
