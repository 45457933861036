.number-input
  &-container
    display: flex
    flex-direction: row
    justify-content: space-between
    margin: 0 0 20px 0

  &-label
    width: 33%
    font-size: 12px
    line-height: 12px
    color: $brand-dark-blue
    +media-query(phone)
      width: 74px

    h6
      +media-query(phone)
        font-size: 15px

    del
      color: $brand-gray-medium
      display: inline-block
      margin-left: 16px

    span
      width: 17px
      height: 17px
      display: inline-block
      vertical-align: top
      background: $brand-gray-xlight
      color: $brand-dark-blue
      font-size: 11px
      line-height: 17px
      text-align: center
      border-radius: 50%
      margin: 0 0 0 5px
      transform: translateY(-8px)

  &-price
    width: 33%
    text-align: right
    color: $brand-dark-blue
    font-size: 19px
    line-height: 26px
    padding: 10px 0 0 0
    +media-query(tablet)
      font-size: 18px
      width: 30%
    +media-query(phone)
      font-size: 15px
      width: 94px

.counter
  &-container
    width: 33%
    font-size: 0
    line-height: 0
    white-space: nowrap
    text-align: center
    +media-query(phone)
      width: 94px

    &__button
      display: inline-block
      vertical-align: top
      font-size: 22px
      line-height: 26px
      color: $brand-gray-medium
      box-sizing: border-box
      width: 42px
      height: 42px
      text-align: center
      border: 1px solid $brand-gray-border
      +media-query(tablet)
        width: 34px
      +media-query(phone)
        width: 30px

      &_left
        border-radius: 6px 0 0 6px

      &_right
        border-radius: 0 6px 6px 0

    &__input
      border: none
      border-top: 1px solid #D9D9D9
      border-bottom: 1px solid #D9D9D9
      height: 42px
      text-align: center
      font-size: 16px
      line-height: 26px
      color: #2B388F
      width: 40px
      box-sizing: border-box
      display: inline-block
      vertical-align: top
      border-radius: 0
      padding: 12px 10px 10px 10px
      +media-query(wide)
        padding-left: 10px
        padding-right: 10px
      +media-query(phone)
        width: 40px

      input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

      input[type="number"]
        -moz-appearance: textfield
