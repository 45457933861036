.Dropdown-root
  position: relative

.Dropdown-control
  padding: 8px 52px 8px 10px
  transition: all 200ms ease

.Dropdown-arrow-wrapper
  display: block
  height: 0
  position: absolute
  right: 24px
  top: 4px
  width: 0
.Dropdown-placeholder
  svg
    display: none

.Dropdown-menu
  background-color: white
  margin-top: 8px
  overflow-y: auto
  position: absolute
  top: 100%
  left: -88px
  width: 300px
  z-index: 1000
  -webkit-overflow-scrolling: touch
  border-radius: 6px
  box-shadow: 0 4px 10px 0 #e5e7eb
  padding: 16px 0


  .Dropdown-group > .Dropdown-title
    padding: 8px 10px
    color: rgba(51, 51, 51, 1)
    font-weight: bold
    text-transform: capitalize

.Dropdown-option
  box-sizing: border-box
  color: #A5A5A5
  cursor: pointer
  display: block
  padding-left: 44px
  text-align: left
  font-size: 17px
  font-weight: 400
  svg
    display: none

  &:last-child
    border-bottom-right-radius: 2px
    border-bottom-left-radius: 2px

  &:hover, &.is-selected
    color: #33B92D
  &.is-selected
   svg
    display: inline
    margin-right: 8px
    margin-left: -28px
    top: 2px
    fill: #33B92D

.Dropdown-noresults
  box-sizing: border-box
  color: #ccc
  cursor: default
  display: block
  padding: 8px 10px