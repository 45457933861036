#main-header
  background: #ffffff
  position: fixed
  z-index: 999
  min-height: 96px
  align-items: center
  display: flex
  top: 0
  left: 0
  width: 100%
  box-shadow: 0px 4px 10px 0px $border-color
  +media-query(tablet)
    min-height: 0

.header-wrap
  display: flex
  flex-direction: row
  justify-content: space-between
  font-size: 26px
  +media-query(desktop)
    flex-direction: row
    justify-content: space-between

  .main-logo
    width: 9em
    height: 3em
    display: flex
    align-items: center
    +media-query(tablet)
      font-size: 20px
    +media-query(phone)
      font-size: 16px

    img
      display: block
      width: 100%
      height: auto
      position: relative
      +media-query(tablet)
        width: 135px
      +media-query(phone)
        width: 115px
        margin: auto


.header-logo
  margin: auto
  +media-query(desktop)
    width: auto
  +media-query(tablet)
    padding: 10px 0 5px 0

.header-contacts
  display: flex
  flex-direction: row
  width: 40%
  align-items: center
  +media-query(wide)
    flex-direction: column
    justify-content: center
    align-items: flex-start

  a
    line-height: 26px
    color: $brand-dark-blue

    i
      display: inline-block
      vertical-align: baseline
      margin-right: 9px
      transform: translateY(1)

  &__email
    padding-right: 21px
    font-size: 18px
    color: $brand-dark-blue
    +media-query(tablet)
      padding-top: 8px

  &__phone
    transform: translateY(-1px)
    font-size: 19px
    color: $brand-dark-blue
    +media-query(desktop)
      padding-top: 8px
    span
      color: $brand-orange

.header-icon
  align-self: center
  width: 40%
  display: flex
  justify-content: flex-end

  a
    display: block
    font-size: 16px
    line-height: 26px
    color: $brand-dark-blue
    width: 4em
    text-align: center
    +media-query(tablet)
      font-size: 12px
      display: none
      +media-query(phone)
        font-size: 8px

.header__button
  font-size: 16px
  +media-query(tablet)
    font-size: 12px
    +media-query(phone)
      font-size: 8px

.svg-inline--fa.fa-w-14
  width: 36px
  height: 36px

  &:hover
    color: $brand-blue
    width: 40px
    height: 40px
    transition: all 0.3s ease

.svg-inline--fa.fa-w-16
  width: 32px
  height: 32px

  &:hover
    color: $brand-blue
    width: 36px
    height: 36px
    transition: all 0.3s ease

a.header-phone
  &_tablet
    display: block

  &_desktop
    display: block
    +media-query(tablet)
      display: none

.header-select-currency-wrap
  align-self: center
  +media-query(tablet)
    display: none
.header-icon--right .header-select-currency-wrap
  display: block
  padding-bottom: 16px

.header-mobile-toggler
  display: none
  +media-query(tablet)
    display: block
    min-width: 42px
    max-width: 42px
    margin-left: 13px
    height: 42px
    border-radius: 50%
    position: relative
    background: #33B92D
    transform: translateY(2px)
    
.header-mobile-toggler span 
  transition: transform 0.3s, opacity 0.3s
  transform-origin: 50% 50%
  width: 16px
  height: 2px
  background: #ffffff
  position: absolute
  display: block
  left: 13px


.header-mobile-toggler span:nth-child(1)
  top: 15px


.header-mobile-toggler span:nth-child(2)
  top: 20px


.header-mobile-toggler span:nth-child(3)
  top: 25px


.show-mobile-menu .header-mobile-toggler span:nth-child(1)
  transform: translate(0, 5px) rotate(45deg)


.show-mobile-menu .header-mobile-toggler span:nth-child(2)
  opacity: 0


.show-mobile-menu .header-mobile-toggler span:nth-child(3)
  transform: translate(0, -5px) rotate(-45deg)
  
.header-inner--right
  position: absolute
  top: 100%
  left: 20px
  width: calc(100% - 40px)
  display: block
  text-align: center
  background: #ffffff
  padding: 20px
  opacity: 0
  box-sizing: border-box
  transform: translateY(3px)
  transition: transform 0.3s, opacity 0.3s
  pointer-events: none
  box-shadow: 0 3px 26px rgba(0, 0, 0, 0.06)
  border-radius: 4px

.header-icon--right
  display: flex
  flex-direction: column
  align-items: center
  a
    display: block

.show-mobile-menu .header-inner--right
  opacity: 1
  transform: translateY(13px)
  pointer-events: auto

.mobile-menu-buttons
  display: flex
  justify-content: center
