.select-city
  display: flex
  padding: 0 27px 0 27px
  background: #ffffff
  box-shadow: 0 4px 36px rgba(0, 0, 0, 0.06)
  border-radius: 12px
  width: 300px
  height: 56px
  margin: 0
  border: none
  align-items: center

  .Dropdown-placeholder
    font-size: 18px
    width: 120px

  .Dropdown-arrow-wrapper
    top: 0
    right: 0
  span
    color: #A5A5A5
    font-size: 18px
    line-height: 26px
    display: inline-block
    margin-right: 6px
    white-space: nowrap
    cursor: default

  i
    display: inline-block
