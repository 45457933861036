html
  scroll-behavior: smooth
  line-height: $line-height-base

  // Reset the box-sizing
  box-sizing: border-box

  *
    &, &:before, &:after
      box-sizing: inherit

  // Reset outline
  *
    outline: none

  input
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

  // Reset buttons
  button
    padding: 0
    border: none
    font: inherit
    color: inherit
    background-color: transparent
    cursor: pointer

  font:
    family: $font-family-base
    size: $font-size-base
    weight: $font-weight-normal

  &.fixed
    overflow: hidden

  &.fixed-popup
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    overflow: hidden


body
  color: $text-color
  background: $background-color
  position: relative
  overflow-x: hidden
  max-width: 100%
  height: 100%
  padding: 0
  margin: 0

  a
    text-decoration: none
    transition: color 300ms

  p
    margin: 0 0 20px 0

  &:before
    content: ''
    height: 486px
    pointer-events: none
    position: absolute
    top: 112px
    background: url('../../images/bg_left.png') no-repeat top right
    width: 346px
    left: 0
    +media-query(desktop)
      display: none

  &:after
    content: ''
    height: 486px
    pointer-events: none
    position: absolute
    top: 112px
    background: url('../../images/bg_right.png') no-repeat top left
    width: 398px
    right: 0
    +media-query(desktop)
      display: none

  &.fixed-popup
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    overflow: hidden

h1, h1 a, h1, span, h2, h2 a, h2, span, h3, h3 a, h3, span, h4, h4 a, h4, span, h5, h5 a, h5, span, h6, h6 a, h6, span
  color: $brand-dark-blue
  font-weight: $font-weight-normal
  font-family: $font-family-base

h1
  font-size: 38px
  line-height: 38px
  margin: 0 0 38px 0

h2
  font-size: 31px
  line-height: 31px
  margin: 0 0 31px 0

h3
  font-size: 26px
  line-height: 26px
  margin: 0 0 26px 0

h4
  font-size: 22px
  line-height: 22px
  margin: 0 0 22px 0

h5
  font-size: 20px
  line-height: 20px
  margin: 0 0 20px 0

h6
  font-size: 18px
  line-height: 18px
  margin: 0 0 18px 0
  +media-query(tablet)
    font-size: 16px
    line-height: 16px
    margin: 8px 0 16px 0

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"],
input[type="datetime"],
input[type="url"],
select,
textarea
  outline: none
  font-family: 'Cabin', sans-serif
  /* box-sizing: border-box; */
  /* border: 1px solid #DCDDE5; */
  /* border-radius: 4px; */
  background: #fff
  /* color: #848484; */
  /* font-size: 16px; */
  /* line-height: 26px; */
  /* width: 100%; */
  /* padding: 12px 16px 10px 16px; */
  letter-spacing: 0.32px

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield
