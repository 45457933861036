.container
  width: 1170px
  margin: 0 auto
  +media-query(wide)
    box-sizing: border-box
    width: 100%
    padding: 0 20px
  +media-query(tablet)
    padding: 0 16px
  +media-query(phone)
    padding: 0 10px

div#root > .container
  margin-top: 120px
  position: relative
  z-index: 5
  min-height: calc(100vh - 310px)
  +media-query(wide)
    min-height: calc(100vh - 290px)
  +media-query(tablet)
    min-height: calc(100vh - 189px)
    margin-top: 90px

.breadcrumb-wrap
  margin: 90px 0 0 0
  +media-query(desktop)
    margin: 28px 0 32px 0

.breadcrumb-list
  display: flex
  margin: 0 auto
  flex-direction: row
  justify-content: center
  padding: 0
  min-height: 60px
  max-width: 770px
  +media-query(desktop)
    width: 100%
  +media-query(tablet)
    min-height: 50px

  li
    margin: 0 8px 0 0
    list-style: none
    background: #ffffff
    color: $brand-dark-blue
    font-size: 18px
    line-height: 24px
    white-space: nowrap
    position: relative
    cursor: grab
    width: 187px
    text-align: center
    padding: 19px 0 0 0
    +media-query(desktop)
      text-align: center
      width: calc(25% - 40px)
      padding: 19px 20px 0 20px
    +media-query(tablet)
      margin: 0 5px 0 0
      font-size: 15px
      width: calc(25% - 15px)
      text-align: left
      padding: 14px 10px 0 20px

    &:first-child
      border-radius: 8px 0 0 8px

      &:before
        display: none

    &:last-child
      margin: 0
      border-radius: 0 8px 8px 0

      &:after
        display: none

    &:after
      content: ''
      width: 0
      height: 0
      border-style: solid
      border-width: 30px 0 30px 14px
      border-color: transparent transparent transparent #ffffff
      position: absolute
      right: -14px
      top: 0
      z-index: 3
      +media-query(tablet)
        border-width: 25px 0 25px 10px
        right: -10px

    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      z-index: 2
      width: 0
      height: 0
      border-style: solid
      border-width: 30px 0 30px 14px
      border-color: transparent transparent transparent $brand-gray
      +media-query(tablet)
        border-width: 25px 0 25px 10px

    svg
      margin-right: 9px
      vertical-align: top
      +media-query(tablet)
        display: none

    &.is-active
      color: #FFFFFF
      background: $brand-blue

      &:after
        content: ''
        width: 0
        height: 0
        border-style: solid
        border-width: 30px 0 30px 14px
        border-color: transparent transparent transparent $brand-blue
        +media-query(tablet)
          border-width: 25px 0 25px 10px
          right: -10px
form
  margin-top: 2vw
  min-width: 500px
  align-self: center
  border-radius: 7px
  padding: 40px
  background: $brand-gray-light
  z-index: 999
  +media-query(tablet)
    min-width: auto
    padding: 20px 4px

.loader-container
  position: fixed
  height: 100%
  width: 100%
  top: 0
  left: 0
  background: white
  z-index: 10