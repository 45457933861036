.tour-search-bar
  display: flex
  padding: 0 27px 0 27px
  background: #ffffff
  box-shadow: 0 4px 36px rgba(0, 0, 0, 0.06)
  border-radius: 12px
  margin: 39px 0
  position: relative
  z-index: 88
  align-items: center
  height: 82px

.search-block__item .input-wrap--select:after
  pointer-events: none

.tour-search-bar
  .search-block__item
    &.search-block__item--location
      width: calc(51% - 71px)
      margin: 0
      border: none
      align-items: center

      .input-wrap--select
        width: calc(100% - 66px)

      .custom_select_wrapper, .input-wrap--select select
        width: 100%

    &.search-block__item--language
      width: calc(49% - 71px)
      margin: 0
      border: none

      .input-wrap--select
        width: calc(100% - 111px)

      .custom_select_wrapper, .input-wrap--select select
        width: 100%
    &.search-block__item--input
      width: 100%
      margin: 0
      border: none

      .ticket-select-label
        font-size: 24px
  .search-block__item-divider
    width: 1px
    height: 39px
    background: #E2E2E2
    display: block
    margin: 0 27px 0 33px
    transform: translate(-1px, 4px)

  .search-block__item:after
    display: none

  .search-block__button-wrap
    width: 49px
    margin-left: 33px

    .search-block--submit
      width: 49px
      height: 49px
      padding: 14px 13px 13px 14px
      border-radius: 50%

.content-catalog-wrap
  padding-bottom: 97px


.search-block-wrap
  position: relative
  z-index: 7
  margin: 0 0 0 0

  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    height: 41px
    width: 100%
    background: #ffffff

.search-block
  position: relative
  z-index: 5
  display: flex
  flex-direction: row
  border-radius: 12px
  background: #fff
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.06)
  padding: 18px 27px 15px 27px

.search-block--submit
  display: block
  width: 84px
  height: 49px
  text-align: center
  border-radius: 8px
  background: #f6921e
  box-shadow: 0px 3px 10px rgba(246, 146, 30, 0.22)
  box-sizing: border-box
  padding: 14px 0 0 0
  transition: background 300ms

  &:hover
    background: #E28314

.search-block__item .search-block--submit span
  display: none

.search-block--submit i.search-icon
  width: 22px
  height: 22px
  //background: url('../img/icons/icon-search.tsx') no-repeat 0 0
  display: inline-block

.search-block__item
  display: flex
  align-items: baseline
  flex-direction: row
  min-height: 39px
  box-sizing: border-box
  margin-right: 30px

  &:after
    content: ''
    width: 1px
    height: 39px
    background: #E2E2E2
    margin-left: 25px
    transform: translateY(-7px)

  &:last-child
    margin-right: 0

  &:nth-child(3):after, &:last-child:after
    display: none

  span
    color: #A5A5A5
    font-size: 18px
    line-height: 26px
    display: inline-block
    margin-right: 6px
    white-space: nowrap
    cursor: default

  i
    display: inline-block

  .input-wrap--select
    position: relative

    &:after
      content: ''
      width: 12px
      height: 7px
      //background: url('../img/icons/icon-dropdown.svg') no-repeat center
      position: absolute
      right: 0
      top: 9px

  select
    -webkit-box-shadow: none
    -webkit-appearance: none
    border: none
    padding: 1px 0 0 0
    color: #1B214A
    font-size: 18px
    line-height: 26px
    outline: none
    cursor: pointer

.search-block__item--location

  .custom_select_wrapper, select
    width: 232px

.search-icon--location
  width: 16px
  height: 20px
  transform: translateY(2px)
  margin-right: 6px

.search-block__item--language
  .custom_select_wrapper, select
    width: 179px

.search-icon--language
  width: 19px
  height: 19px
  transform: translateY(2px)
  margin-right: 5px

.search-block__item .input-wrap--date
  position: relative
  margin-right: 22px

.search-block__item--date input
  &[type="text"]
    width: 202px
    border: none
    color: #1B214A
    font-size: 18px
    line-height: 26px
    cursor: pointer
    padding: 0

  &::-moz-placeholder, &::-webkit-input-placeholder, &::-ms-input-placeholder
    color: #A5A5A5

.search-block__item .input-wrap--date:after
  content: ''
  width: 12px
  height: 7px
  //background: url('../img/icons/icon-dropdown2.svg') no-repeat center
  position: absolute
  right: 0
  top: 9px

.search-icon--date
  width: 18px
  height: 18px
  transform: translateY(2px)
  margin-right: 11px

.content-tours-wrap
  padding: 0 0 70px 0

.tickets-grid-wrap
  display: flex
  flex-wrap: wrap
  margin: -15px
  padding-bottom: 51px

  .tickets-grid-item
    margin: 15px
    cursor: pointer


  &.tickets-grid-1cols .tickets-grid-item
    width: calc(100% - 30px)
    +media-query(wide)
      width: calc(25% - 20px)
      margin: 10px
    +media-query(desktop)
      width: calc(50% - 20px)
    +media-query(tablet)
      width: calc(100% - 20px)

  &.tickets-grid-2cols .tickets-grid-item
    width: calc(50% - 30px)
    +media-query(wide)
      width: calc(25% - 20px)
      margin: 10px
    +media-query(desktop)
      width: calc(50% - 20px)
    +media-query(tablet)
      width: calc(100% - 20px)
      display: flex
      flex-direction: row
      padding: 21px 36px 16px 21px
      background: #ffffff
      border-radius: 6px
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04)

  &.tickets-grid-3cols .tickets-grid-item
    width: calc(33.33% - 30px)
    +media-query(wide)
      width: calc(25% - 20px)
      margin: 10px
    +media-query(desktop)
      width: calc(50% - 20px)
    +media-query(tablet)
      width: calc(100% - 20px)
      display: flex
      flex-direction: row
      padding: 21px 36px 16px 21px
      background: #ffffff
      border-radius: 6px
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04)

  &.tickets-grid-4cols .tickets-grid-item
    width: calc(25% - 30px)
    +media-query(wide)
      width: calc(25% - 20px)
      margin: 10px
    +media-query(desktop)
      width: calc(50% - 20px)
    +media-query(tablet)
      width: calc(100% - 20px)
      display: flex
      flex-direction: row
      padding: 21px 36px 16px 21px
      background: #ffffff
      border-radius: 6px
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04)

.tickets-grid-item--image
  border-radius: 6px 6px 0 0
  overflow: hidden
  height: 152px
  +media-query(wide)
    height: auto
  +media-query(tablet)
    min-width: 73px
    max-width: 73px
    margin-right: 11px


  picture
    +media-query(tablet)
      display: block
      width: 73px
      height: 73px
      border-radius: 50%
      overflow: hidden
      text-align: center
      position: relative

  img
    display: block
    width: 100%
    height: auto
    transition: transform 300ms
    +media-query(tablet)
      height: 100%
      width: auto
      position: absolute
      left: 50%
      top: 0
      transform: translateX(-50%)


  a:hover img
    transform: scale(1.05)

.tickets-grid-item--content
  background: #ffffff
  border-radius: 0 0 6px 6px
  padding: 26px 20px 22px 20px
  height: 160px
  display: flex
  flex-direction: column
  justify-content: space-between
  +media-query(tablet)
    width: calc(100% - 84px)
    padding: 0
    transform: translateY(2px)
    height: auto


  h4
    margin: 0 0 11px 0
    font-weight: normal
    font-size: 22px
    line-height: 26px
    text-align: left
    color: #1b214a
    +media-query(tablet)
      font-size: 19px
      line-height: 24px
      margin-bottom: 4px

    a
      color: #1B214A

.tickets-grid-item--info
  font-size: 21px
  line-height: 26px
  color: #F6921E
  padding-right: 20px
  position: relative
  +media-query(tablet)
    font-size: 19px
    line-height: 24px

  span
    color: #5F5F5F
    font-size: 15px
    line-height: 26px
    display: inline-block
    margin-right: 8px
    +media-query(tablet)
      font-size: 14px

.arrow-right-icon
    position: absolute
    bottom: 11px
    right: -1px
    width: 14px
    height: 10px
    display: block

.tickets-load-more
  width: 184px
  background: #3849BB
  border-radius: 27px
  height: 54px
  color: #ffffff
  font-size: 16px
  line-height: 26px
  box-sizing: border-box
  display: block
  margin: 0 auto
  text-align: center
  position: relative
  transition: background 300ms

  &:hover
    background: #1B214A

.refresh-icon
  fill: #ffffff
  display: inline-block
  vertical-align: top
  width: 14px
  height: 15px
  transform: translateY(4px)
  margin-left: 7px

.option-image
  display: inline-block
  vertical-align: top
  margin: 0 13px 0 0

