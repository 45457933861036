.ticket-title
  +media-query(tablet)
    font-size: 26px
    line-height: 31px

  span
    display: block
    color: $brand-green
    font-size: 20px
    line-height: 31px
    margin: 0 0 15px 0
    +media-query(tablet)
      font-size: 17px

    svg
      vertical-align: top
      transform: translateY(7px)
      margin: 0 10px 0 0

.ticket-select-label
  font-size: 26px
  line-height: 31px
  justify-content: space-between
  align-items: center
  display: flex
  flex-direction: row
  +media-query(tablet)
    font-size: 22px
    display: block

  svg
    transform: translateY(3px)

.ticket-wrap
  padding-bottom: 69px

.ticket-item
  margin-top: 30px

  &-container
    border-right: 1px solid $border-color
    color: $brand-gray-medium
    font-size: 19px
    line-height: 26px
    padding: 30px 30px 24px 36px
    +media-query(wide)
      padding: 20px 20px 14px 26px
      font-size: 16px
      line-height: 24px
    +media-query(desktop)
      min-width: 33.33%
      max-width: 33.33%
      border: none

  &--preview
    display: flex
    flex-direction: row
    background: #ffffff
    border-radius: 8px
    overflow: hidden
    +media-query(desktop)
      flex-wrap: wrap

    > div
      box-sizing: border-box
      align-self: center
      +media-query(tablet)
        align-self: auto

  &__image
    +media-query(wide)
      min-width: 220px
      max-width: 220px
    +media-query(desktop)
      min-width: 33.33%
      max-width: 33.33%
    +media-query(tablet)
      display: none
    width: 240px

    img
      display: block
      width: 100%
      height: auto

  &__title
    width: 364px
    +media-query(tablet)
      min-width: 100%
      max-width: 100%
      padding: 28px 20px 12px 20px
      border: none
      font-size: 0
      line-height: 0
      color: rgba(0, 0, 0, 0)
      position: relative
      display: flex
      flex-direction: column-reverse

    &-active
      &:before
        +media-query(tablet)
          background: $brand-blue
          border-color: $brand-blue

      &:after
        +media-query(tablet)
          background: #ffffff
        transform: rotate(90deg)
        right: 31px
        top: 37px

    h3
      margin: 10px 0 10px 0
      +media-query(wide)
        font-size: 23px
        line-height: 23px
      +media-query(tablet)
        margin-top: 15px

    span
      font-size: 17px
      color: $brand-gray-dark
      display: block
      letter-spacing: 0.17px
      +media-query(wide)
        font-size: 15px
        line-height: 24px
      +media-query(tablet)
        font-size: 14px

      svg
        transform: translateY(2px)
        margin: 0 5px 0 1px
        +media-query(tablet)
          width: 12px
          height: 12px
          transform: translateY(1px)
          margin: 0 3px 0 0

  &__price
    width: 310px
    +media-query(tablet)
      min-width: calc(100% - 145px)
      max-width: calc(100% - 145px)
      border: none
      padding: 0 0 24px 20px
      font-size: 0
      line-height: 0
      color: rgba(0, 0, 0, 0)

    &-wrap
      display: flex
      flex-direction: row
      justify-content: space-between

    &-item
      width: 75px
      color: $brand-blue
      font-size: 25px
      line-height: 26px
      padding: 10px 0 0 0
      +media-query(tablet)
        font-size: 17px
        padding: 0

      span
        font-size: 17px
        line-height: 20px
        color: $brand-gray-dark
        display: block
        margin-top: 14px
        +media-query(wide)
          font-size: 15px
          line-height: 24px
        +media-query(tablet)
          font-size: 13px
          margin: -3px 0 0 0

    &-sum
      display: flex

      span
        margin-top: 5px
        +media-query(tablet)
          margin: 3px 0 0 0

  &__button
    width: 255px
    display: flex
    justify-content: center
    align-self: center
    +media-query(wide)
      padding: 0 16px
    +media-query(desktop)
      width: 100%
      padding: 20px 20px 20px 20px
    +media-query(tablet)
      min-width: 112px
      max-width: 112px
      border: none
      padding: 0px 0px 0 20px
      align-self: auto

    .button
      +media-query(desktop)
        width: 100%
      +media-query(tablet)
        min-width: 112px
        max-width: 112px
        border: none
        height: 40px
        i
          +media-query(tablet)
            display: none

  &--detail
    +media-query(tablet)
      flex-direction: column

    &-wrap
      display: none
      padding: 0 0 0 240px
      +media-query(wide)
        padding-left: 220px
      +media-query(desktop)
        padding-left: 0
    display: flex
    flex-direction: row
    padding: 30px 0 30px 0
    +media-query(wide)
      padding: 20px 0 0 0

    > div
      width: calc(50% - 15px)
      background: #ffffff
      border-radius: 8px
      box-sizing: border-box
      padding: 33px 35px 43px 34px
      +media-query(wide)
        padding: 23px 20px 33px 20px
      +media-query(tablet)
        width: 100%

      &:first-child
        +media-query(tablet)
          margin-bottom: 20px
    &--one
      width: 100% !important
      margin-left: 0 !important

    h4
      margin-bottom: 36px

    &--right
      margin-left: 30px
      +media-query(tablet)
        margin-left: 0

      h4
        margin-bottom: 23px

    &--left
      +media-query(tablet)
        padding-bottom: 17px


  &-summary
    display: flex
    justify-content: space-between
    font-size: 19px
    line-height: 26px
    color: $brand-dark-blue
    padding: 14px 0 0px 0

    &__label
      +media-query(tablet)
        font-size: 16px

    &__summ
      +media-query(tablet)
        font-size: 18px

    span
      color: $brand-gray-medium

  &-per-trip
    display: flex
    justify-content: space-between
    color: $brand-dark-blue
    font-size: 18px
    padding: 0px 0 24px 0

    span
      color: $brand-gray-medium
      font-size: 12px

  &-time
    margin-bottom: 16px

    &__label
      color: $brand-dark-blue
      font-size: 18px
      line-height: 26px
      padding-top: 9px
      display: flex
      justify-content: space-between

      &_disabled
        color: $brand-gray-xDark
        opacity: 0.8

      &_right
        width: 150px
        display: flex
        justify-content: flex-end

    &__select
      width: 150px
      height: 34px

  &-date
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: -5px
    border-top: 1px solid $brand-gray-border

    &__label
      color: $brand-dark-blue
      font-size: 18px
      line-height: 26px
      padding-top: 9px

    &__input
      width: 150px
      display: flex
      align-items: flex-end
      justify-content: flex-end

  &__description
    margin-top: 16px

.ticket-item_open
  display: block

.ticket-item_close
  display: none

.content-with-sidebar
  display: flex
  flex-direction: row

  .content-part
    width: calc(100% - 400px)
    +media-query(wide)
      width: calc(100% - 260px)
    +media-query(desktop)
      width: 100%


.content-block-wrap
  background: #ffffff
  border-radius: 4px
  box-sizing: border-box
  padding: 20px 30px 20px 30px
  margin: 16px 0 16px 0
  +media-query(tablet)
    margin: 0 0 16px 0
  > h3
    font-size: 27px
    line-height: 1
    color: #1B214A
    font-weight: 600
    margin: 21px 0 0 0

.content-description-block
  padding: 20px 0 18px 0

  p:last-child
    margin-bottom: 0

  .category-label
    font-size: 16px
    line-height: 20px
    color: #F6921E
    font-weight: 500
    display: inline-block
    margin: 0 0 20px 0
    +media-query(tablet)
      display: none

  h2
    letter-spacing: 0
    color: #1B214A
    font-size: 31px
    line-height: 1
    margin: 0 0 16px 0
    font-weight: 500

  .travel-lang-label
    font-size: 17px
    line-height: 1.5
    color: #A5A5A5
    margin: 0 0 17px 0

    span
      color: #1B214A

    i
      width: 22px
      height: 15px
      display: inline-block
      vertical-align: top
      margin-left: 6px
      transform: translateY(4px)

.content-description-block p
  font-weight: 400
  color: #848484
  font-size: 16px
  line-height: 24px
  margin: 0 0 24px 0

content-itinerary-wrap
  padding-top: 41px

.content-itinerary-wrap h3
  margin: 0 0 15px 0

ul.tour-itinerary-list
  padding: 0
  margin: 0 0 13px 0

  li
    padding: 5px 0
    margin: 0
    list-style: none
    font-size: 16px
    line-height: 25px

    ul
      padding: 8px 0 0 34px
      margin: 0 0 -3px 0

      li
        padding: 5px 0 5px 19px
        margin: 0
        font-size: 15px
        line-height: 20px
        position: relative

        &:before
          content: ''
          display: block
          position: absolute
          left: 0
          top: 12px
          width: 6px
          height: 6px
          border-radius: 3px
          background: #F6921E

.tour-itinerary-list i
  display: inline-block
  vertical-align: top

  &.tour-itinerary--transfer
    width: 16px
    height: 12px
    margin: 0 13px 0 1px
    transform: translateY(6px)
    //background: url('../img/travel-icons/icon--transfer.svg') no-repeat center

  &.tour-itinerary--shipping
    width: 19px
    height: 19px
    margin: 0 11px 0 0
    transform: translate(-1px, 3px)
    //background: url('../img/travel-icons/icon--ship.svg') no-repeat center

.article-read-more article
  display: none

a.read-more-button
  display: block
  border-top: 1px solid #E4E4E6
  padding: 20px 30px 0 30px
  font-size: 16px
  line-height: 20px
  font-weight: 500
  color: #1B214A
  margin: 23px -30px -20px -30px

  &:after
    content: ''
    width: 13px
    height: 13px
    margin-left: 10px
    transform: translateY(1px)
    display: inline-block
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.662' height='12.662' viewBox='0 0 12.662 12.662'%3E%3Cpath id='Path_3493' data-name='Path 3493' d='M7.121,5.541V0H5.541V5.541H0v1.58H5.541v5.541h1.58V7.121h5.541V5.541Z' fill='%231b214a'/%3E%3C/svg%3E%0A")


.sidebar-part
  width: 370px
  margin-left: 30px
  +media-query(wide)
    margin-left: 20px
    width: 240px
  +media-query(desktop)
    display: none

aside.sidebar-part
  position: relative
  margin-top: 95px
  padding-bottom: 30px


.widget-ticket-wrap
  position: -webkit-sticky

  /* Safari
  position: sticky
  top: 120px
  padding-top: 30px

.widget-ticket
  color: #ffffff
  overflow: hidden
  border-radius: 4px
  background: #2637AA no-repeat top right
  padding: 25px 36px 21px 30px

  > span
    font-size: 16px
    line-height: 26px
    letter-spacing: 0.32px
    font-weight: 500
    display: block
    color: #ffffff
    margin: 0 0 12px 0

  .widget-ticket__prices
    display: flex
    margin: 0 0 24px 0

    > div
      font-size: 30px
      line-height: 1
      font-weight: 500
      +media-query(wide)
        font-size: 20px

      > span
        color: #B7BCDD
        font-size: 16px
        line-height: 19px
        font-weight: 400
        display: block
        margin: 5px 0 0 0

    .widget-ticket__prices-child
      margin-left: 26px
      +media-query(wide)
        margin-left: 20px

.widget-ticket__seperator
  margin: 0 -36px 14px -30px
  position: relative
  height: 15px
  display: block

  &:before, &:after
    content: ''
    position: absolute
    top: -7px
    width: 15px
    height: 15px
    background: #F5F6FA
    border-radius: 50%

  &:before
    left: -7px

  &:after
    right: -7px

.widget-ticket__button
  border-radius: 27px
  width: 100%

  &:before
    content: ''
    position: absolute
    width: calc(100% - 10px)
    left: 5px
    top: 0
    height: 100%
    pointer-events: none
    border-radius: 27px
    box-shadow: 0px 3px 15px rgba(63, 195, 57, 0.23)
    transition: box-shadow 300ms

  &:hover
    background: #139D0D

  i
    width: 21px
    height: 21px
    display: inline-block
    vertical-align: top
    display: inline-block
    //background: url('../img/travel-icons/button-icon--ticket2.svg') no-repeat center
    margin: 0 6px 0 0
    transform: translateY(0px)

.widget-ticket-fixed
  display: none
  +media-query(desktop)
    display: block
    position: fixed
    left: 0
    bottom: 0
    width: 100%
    background: #ffffff
    padding: 16px 30px
    z-index: 99
    box-sizing: border-box
    box-shadow: 0px 4px 8px 8px $border-color
    +media-query(tablet)
      padding: 8px 30px

.gallery
  display: grid
  grid-template: repeat(3, 1fr) / repeat(3, 1fr)
  gap: clamp(5px, 1vw, 8px)
  picture
    cursor: pointer
    img
      border-radius: 6px
    source
      border-radius: 6px
