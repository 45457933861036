.accordeon
  &-wrapper
    background: #ffffff
    border: 1px solid $brand-gray-border
    box-shadow: 0 3px 16px rgb(0 0 0 / 4%)
    border-radius: 6px
    margin-bottom: 30px

  &-item
    &-wrap
      border-bottom: 1px solid $brand-gray-border

    &-title
      color: $brand-dark-blue
      font-size: 17px
      line-height: 20px
      font-weight: 700
      transition: color 0.3s
      position: relative
      padding: 21px 36px 21px 36px
      cursor: pointer
      -webkit-touch-callout: none
      -webkit-user-select: none
      -khtml-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      svg
        position: absolute
        left: 18px
        top: 26px
        transition: transform 0.3s

      &_active
        color: $brand-blue
        padding: 21px 36px 0 36px

        &:after
          margin-top: 21px
          content: ''
          width: 100%
          display: block
          border-bottom: 1px solid #D9D9D9

        svg
          color: $brand-blue
          transform: rotate(90deg)


    &-content
      padding: 16px 36px 16px 36px
      display: none

      &_active
        display: block

      p, ul, li, span, h1, h2, h3, h4, h5, h6
        color: $brand-gray-xDark
        margin: 0 0 16px 0

      p, ul, li, span
        font-size: 16px
        line-height: 24px



