.thumbs
  +media-query(tablet)
    display: none
.thumb
  width: 155px
  height: 106px
  border-radius: 6px
  padding: 0 4px
  picture
    img
      border-radius: 6px
    source
      border-radius: 6px

.alice-carousel

  &__dots
    margin: -36px 3px 5px
    position: relative
    display: flex
    justify-content: center
    &-item:not(.__custom)
      width: 10px
      height: 10px
      box-sizing: border-box
      border: 2px solid #ffffff
      background: rgba(255,255,255,0)
      transition: background 0.3s
      &.__active
        background: rgba(255,255,255,1)
  &__prev-btn
    padding: 0 20px
    width: calc(100% - 90px)
    +media-query(tablet)
      width: calc(100% - 65px)
    &-item
      width: 50px
      height: 50px
      position: absolute
      top: 50%
      margin-top: -25px
      background: #ffffff
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      z-index: 15
      +media-query(tablet)
        width: 25px
        height: 25px

  &__next-btn
    padding: 0 20px
    width: 90px
    +media-query(tablet)
      width: 65px
    &-item
      width: 50px
      height: 50px
      position: absolute
      top: 50%
      margin-top: -25px
      background: #ffffff
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      z-index: 15
      +media-query(tablet)
        width: 25px
        height: 25px

.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after
  font-size: 30px
  font-family: system-ui
  font-weight: 100
  +media-query(tablet)
    font-size: 14px

.alice-carousel__prev-btn [data-area]::after
  padding: 0 4px 0 0
  +media-query(tablet)
    padding: 0

.alice-carousel__next-btn [data-area]::after
  padding: 0 0 0 4px
  +media-query(tablet)
    padding: 0

.alice-carousel__stage-item
  picture
    img
      border-radius: 6px
    source
      border-radius: 6px